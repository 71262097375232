/* .gradient-text {
  background: linear-gradient(90deg, #d07aca, #00dcff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
  width: fit-content;
  transition: background-position 0.5s ease;
  background-size: 200% 200%;
  background-position: 0% 50%;
}

.gradient-text:hover {
  background-position: 100% 50%;
} */

/* GLOW */
/* .gradient-text {
  background: linear-gradient(90deg, #d07aca, #00dcff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
  width: fit-content;
  transition: text-shadow 0.4s ease;
}

.gradient-text:hover {
  text-shadow: 0px 0px 2px rgba(208, 122, 202, 0.9),
    0px 0px 4px rgba(0, 220, 255, 0.9);
} */

/* ROTATE */
/* .gradient-text {
  background: linear-gradient(90deg, #d07aca, #00dcff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
  width: fit-content;
  background-size: 400% 400%;
  transition: background-position 1s ease;
}

.gradient-text:hover {
  background-position: 100% 0%; 
} */

/* WAVE */
.gradient-text {
  background: linear-gradient(90deg, #d07aca, #00dcff, #d07aca, #00dcff);
  background-size: 400% 400%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
  width: fit-content;
  background-position: 0% 50%;
  transition: background-position 1s ease; /* Smooth transition for hover and unhover */
}

.gradient-text:hover {
  background-position: 50% 100%; /* Wave effect on hover */
}

/* FADE */
/* .gradient-text {
  background: linear-gradient(90deg, #d07aca, #00dcff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
  width: fit-content;
  opacity: 1;
  transition: opacity 0.4s ease, background 0.4s ease;
}

.gradient-text:hover {
  opacity: 0.5;
} */
